import React, {createContext, useContext, useEffect} from 'react';
import firebase, {FCM_TOKEN_STRING} from './firebase';
import {parseCookies, setCookie} from 'nookies';
import {useFeedback} from './feedbackContext';
import {JWT_STRING} from './apolloClient';
import {useUpdateGcmTokenMutation} from '@graphql/generated/graphql';

interface INotificationProviderProps {}
interface INotificationContext {
  onMessage: (messaging: firebase.messaging.Messaging) => Promise<void>;
}

export const NotificationContext = createContext<INotificationContext>(null);
export const useNotification = (): INotificationContext => useContext(NotificationContext);

const NotificationProvider: React.FunctionComponent<INotificationProviderProps> = ({children}) => {
  const {enqueueFeedback} = useFeedback();
  const [updateGcmToken] = useUpdateGcmTokenMutation();

  const onMessage = async (messaging: firebase.messaging.Messaging) => {
    messaging.onMessage(payload => {
      const isError = payload.notification?.data?.error ? true : false;
      enqueueFeedback(payload.notification?.body, isError);
    });
  };

  const firebaseMessaging = async (messaging: firebase.messaging.Messaging) => {
    try {
      const oldToken = parseCookies()[FCM_TOKEN_STRING];
      const newToken = await messaging.getToken();
      if (newToken !== oldToken && parseCookies()[JWT_STRING]) {
        await updateGcmToken({variables: {idToAdd: newToken, ...(oldToken ? {idToDelete: oldToken} : {})}});
      }
      setCookie(null, FCM_TOKEN_STRING, newToken, {path: '/'});
      onMessage(messaging);
    } catch (e) {
      console.warn('Please enable notification to get order updates');
    }
  };

  useEffect(() => {
    if (!firebase.messaging.isSupported()) return;
    const messaging = firebase.messaging();
    firebaseMessaging(messaging);
  }, []);

  return <NotificationContext.Provider value={{onMessage}}>{children}</NotificationContext.Provider>;
};

export default NotificationProvider;
