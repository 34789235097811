import {useRef} from 'react';

const useDebounce = () => {
  const debounceTimerRef = useRef<ReturnType<typeof setTimeout>>();

  const debounce = async (duration: number = 5) => {
    await new Promise(resolve => {
      clearTimeout(debounceTimerRef.current);
      debounceTimerRef.current = setTimeout(resolve, duration);
      return debounceTimerRef.current;
    });
  };

  return {debounce};
};

export default useDebounce;
