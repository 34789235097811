import {useApollo} from '@lib/apolloClient';
import {ApolloProvider} from '@apollo/client';

import React from 'react';
import Error from './_error';
import Head from 'next/head';
import Bugsnag from '@bugsnag/js';

import FeedbackProvider from '@lib/feedbackContext';
import ActionModalProvider from '@lib/actionModalContext';
import NotificationProvider from '@lib/notificationContext';
import LoaderProvider from '@lib/loaderContext';

import 'react-big-calendar/lib/sass/styles.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'tailwindcss/tailwind.css';
import '../styles/globals.css';

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function MyApp({Component, pageProps}) {
  const apolloClient = useApollo(pageProps);

  return (
    <>
      <Head>
        <title>Merchant | COOX</title>
      </Head>
      {/* @ts-ignore */}
      <ErrorBoundary FallbackComponent={Error}>
        <ApolloProvider client={apolloClient}>
          <LoaderProvider>
            <FeedbackProvider>
              <ActionModalProvider>
                <NotificationProvider>
                  <Component {...pageProps} />
                </NotificationProvider>
              </ActionModalProvider>
            </FeedbackProvider>
          </LoaderProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
