import React from 'react';
import ErrorPage from 'next/error';
import Bugsnag from '../lib/bugsnag';

export declare type IErrorProps = {
  error: globalThis.Error;
  info: React.ErrorInfo;
  clearError: () => void;
  statusCode?: number;
};

export default class Error extends React.Component<IErrorProps> {
  static async getInitialProps(ctx) {
    if (ctx.err) Bugsnag.notify(ctx.err);

    return ErrorPage.getInitialProps(ctx);
  }

  render() {
    return <ErrorPage statusCode={this.props.statusCode || 500} />;
  }
}
