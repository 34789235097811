import React, {createContext, useContext, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import Spinner from '@components/shared/spinner';

interface ILoaderProviderProps {}
interface ILoaderContext {
  showLoader: boolean;
  setShowLoader: (value: boolean) => void;
}

export const LoaderContext = createContext<ILoaderContext>(null);
export const useLoader = (): ILoaderContext => useContext(LoaderContext);

const LoaderProvider: React.FC<ILoaderProviderProps> = ({children}) => {
  const router = useRouter();
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    setShowLoader(false);
  }, [router.push]);

  return (
    <LoaderContext.Provider value={{showLoader, setShowLoader}}>
      {showLoader && (
        <section className="fixed inset-0 flex justify-center items-center bg-white opacity-75 z-50">
          <div className="pb-20 pl-40">
            <Spinner />
          </div>
        </section>
      )}
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
