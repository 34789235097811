import {Dialog, Transition} from '@headlessui/react';
import React, {createContext, useContext, Fragment, useRef, useState} from 'react';

type ConfirmButtonType = {
  action: () => void;
  shouldCloseModal?: boolean;
};

interface IActionModalProviderProps {}

interface IActionModalContext {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setExtraContent: React.Dispatch<React.SetStateAction<JSX.Element>>;
  setOnClickAction: ({action, shouldCloseModal}: ConfirmButtonType) => void;
}

export const ActionModalContext = createContext<IActionModalContext>(null);
export const useActionModal = (): IActionModalContext => useContext(ActionModalContext);

const ActionModalProvider: React.FunctionComponent<IActionModalProviderProps> = ({children}) => {
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [extraContent, setExtraContent] = useState<JSX.Element>(null);
  const [confirmButton, setConfirmButton] = useState<ConfirmButtonType>(null);

  const completeButtonRef = useRef(null);
  const closeModal = () => setIsOpen(false);

  const handleClick = () => {
    if (confirmButton.shouldCloseModal) closeModal();
    confirmButton.action();
  };

  const setOnClickAction = ({action, shouldCloseModal = true}: ConfirmButtonType) => setConfirmButton({action, shouldCloseModal});

  return (
    <ActionModalContext.Provider
      value={{
        setTitle,
        setContent,
        setIsOpen,
        setIsAlert,
        setOnClickAction,
        setExtraContent,
      }}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" initialFocus={completeButtonRef} className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="fixed inset-0 opacity-50 bg-black"></div>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="inline-block w-full max-w-md p-6 mt-8 mb-40 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className={`text-lg font-medium leading-6 ${isAlert ? 'text-red-600' : 'text-green-600'}`}>
                  {title}
                </Dialog.Title>
                <p className="text-sm text-gray-700 whitespace-pre-line">{content}</p>

                {extraContent}

                <div className="flex items-center mt-6">
                  <div className="mr-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 select-none"
                      onClick={closeModal}>
                      Cancel
                    </button>
                  </div>

                  <div>
                    <button
                      type="button"
                      ref={completeButtonRef}
                      className={`inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 select-none ${
                        isAlert ? 'text-red-900 bg-red-100 hover:bg-red-200' : 'text-green-900 bg-green-100 hover:bg-green-200'
                      }`}
                      onClick={handleClick}>
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      {children}
    </ActionModalContext.Provider>
  );
};

export default ActionModalProvider;
